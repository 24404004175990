/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable operator-linebreak */
/* eslint-disable no-console */
import React, { memo, useMemo } from 'react';

import Hotjar from '@hotjar/browser';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import browserStorageHelper from '~/helpers/browser-storage-helper';
import useDidMount from '~/hooks/use-did-mount';

const RedirectToLogin = props => {
    const to = useMemo(() => ({
        pathname: '/login',
        state: { from: props.location },
    }), [props.location]);
    return (
        <Redirect
            to={to}
        />
    );
};

RedirectToLogin.propTypes = {
    location: PropTypes.string.isRequired,
};

const PrivateRoute = memo(({
    render, component, user, ...others
}) => {

    const defaultRoutes = {
        // Recommendation
        few_impressions_list: {
            page: 'few-impressions-list',
            translation: useTranslation('RECOMMENDATION_PAGE').t,
            columns: ['CAMPAIGN_STATUS', 'IMAGE', 'AD_TITLE', 'IN_LEARNING', 'MARKETPLACE', 'SKU', 'CAMPAIGN', 'CAMPAIGN_STRATEGY', 'SALES', 'MARKETPLACE', 'IMPRESSIONS', 'CLICKS', 'AD_DAYS', 'RECOMMENDATION'],
        },
        improve_health_recommendation_list: {
            page: 'improve-health-recommendation-list',
            translation: useTranslation('RECOMMENDATION_PAGE').t,
            columns: ['CAMPAIGN_STATUS', 'IMAGE', 'AD_TITLE', 'IN_LEARNING', 'SKU', 'MARKETPLACE', 'PRICE', 'CLICKS', 'IMPRESSIONS', 'HEALTH'],
        },
        not_attractive: {
            page: 'not-attractive',
            translation: useTranslation('RECOMMENDATION_PAGE').t,
            columns: ['CAMPAIGN_STATUS', 'IMAGE', 'AD_TITLE', 'IN_LEARNING', 'SKU', 'MARKETPLACE', 'CAMPAIGN', 'CAMPAIGN_STRATEGY', 'IMPRESSIONS', 'CLICKS', 'CTR', 'SALES', 'AD_DAYS'],
        },
        high_click_cost_recommendation: {
            page: 'high-click-cost-recommendation',
            translation: useTranslation('RECOMMENDATION_PAGE').t,
            columns: ['CAMPAIGN_STATUS', 'IMAGE', 'AD_TITLE', 'IN_LEARNING', 'CAMPAIGN', 'MARKETPLACE', 'PRICE', 'CLICKS', 'INVESTMENT', 'SALES', 'COST_PER_CLICK', 'AD_DAYS', 'RECOMMENDATION'],
        },
        low_conversion_list: {
            page: 'low-conversion-list',
            translation: useTranslation('RECOMMENDATION_PAGE').t,
            columns: ['CAMPAIGN_STATUS', 'IMAGE', 'AD_TITLE', 'IN_LEARNING', 'CAMPAIGN', 'MARKETPLACE', 'IMPRESSIONS', 'CLICKS', 'CTR', 'INVESTMENT', 'PRICE', 'SALES', 'AD_DAYS'],
        },
        acos_biggest_goal_list: {
            page: 'acos-biggest-goal-list',
            translation: useTranslation('RECOMMENDATION_PAGE').t,
            columns: ['CAMPAIGN_STATUS', 'IMAGE', 'AD_TITLE', 'IN_LEARNING', 'SKU', 'MARKETPLACE', 'CAMPAIGN', 'PRICE', 'SALES', 'AD_DAYS', 'ACOS', 'ACOS_GOAL'],
        },
        consuming_very_investment: {
            page: 'consuming-very-investment',
            translation: useTranslation('RECOMMENDATION_PAGE').t,
            columns: ['CAMPAIGN_STATUS', 'IMAGE', 'AD_TITLE', 'IN_LEARNING', 'SKU', 'MARKETPLACE', 'CAMPAIGN', 'CAMPAIGN_INVESTMENT', 'PRICE', 'INVESTMENT', 'INVESTMENT_REPRESENTATIVENESS', 'CTR', 'SALES', 'AD_DAYS', 'RECOMMENDATION'],
        },
        good_listings: {
            page: 'good-listings',
            translation: useTranslation('RECOMMENDATION_PAGE').t,
            columns: ['IMAGE', 'AD_TITLE', 'IN_LEARNING', 'SKU', 'SKU_MARKETPLACE', 'MARKETPLACE', 'PRICE', 'IMPRESSIONS', 'CLICKS', 'SALES', 'AD_DAYS', 'ACOS', 'ACOS_GOAL'],
        },
        // Ads List Page
        ad_list_page: {
            page: 'ads-list-page',
            translation: useTranslation('AD_LIST_PAGE').t,
            columns: ['IMAGE', 'AD_TITLE', 'MARKETPLACE', 'SKU', 'BUYBOX', 'HEALTH', 'TAGS', 'STOCK', 'ORGANIC_SALES', 'PRICE', 'AD_SITUATION'],
        },
        buybox_listing: {
            page: 'buybox-listing',
            translation: useTranslation('BUYBOX_LISTING_PAGE').t,
            columns: ['IMAGE', 'AD_TITLE', 'MARKETPLACE', 'MARKETPLACE_SKU', 'HEALTH', 'BUYBOX', 'BUYBOX_COMPETITOR', 'STOCK', 'TOTAL_SOLD', 'SALES_REPRESENTATION', 'PRICE', 'COMPETITOR_PRICE', 'PRICE_DIFFERENCE', 'SHIPPING', 'COMPETITOR_SHIPPING', 'SHIPPING_DIFFERENCE', 'TOTAL_PRICE', 'COMPETITOR_TOTAL_PRICE', 'PRICE_DIFFERENCE_TOTAL', 'SHIPPING_TIME', 'COMPETITOR_SHIPPING_TIME', 'TIME_DIFFERENCE'],
        },
        campaigns_list: {
            page: 'campaigns-list',
            translation: useTranslation('CAMPAIGN_VIEW_PAGE').t,
            columns: ['CAMPAIGN_STATUS', 'NAME', 'START_DATE', 'END_DATE', 'DAILY_BUDGET', 'MARKETPLACE', 'CAMPAIGN_TYPE', 'STRATEGY', 'ACOS_GOAL'],
        },
        price_monitoring_listing: {
            page: 'price-monitoring-listing',
            translation: useTranslation('PRICE_MONITORING_PAGE').t,
            columns: ['COLUMN_TITLE_STATUS', 'COLUMN_TITLE_IMAGE', 'COLUMN_TITLE_AD_TITLE', 'COLUMN_TITLE_PRICE', 'COLUMN_TITLE_SHIPPING', 'COLUMN_TITLE_TOTAL_COST', 'COLUMN_TITLE_COMPETITOR_IMAGE', 'COLUMN_TITLE_AD_COMPETITOR_TITLE', 'COLUMN_TITLE_COMPETITOR_PRICE', 'COLUMN_TITLE_COMPETITOR_SHIPPING', 'COLUMN_TITLE_COMPETITOR_TOTAL_PRICE', 'COLUMN_TITLE_PRICE_DIFFERENCE', 'COLUMN_TITLE_SHIPPING_DIFFERENCE', 'COLUMN_TITLE_TOTAL_DIFFERENCE', 'COLUMN_TITLE_UPDATED_AT'],
        },
        campaign_report_list: {
            page: 'campaign-report-list',
            translation: useTranslation('CAMPAIGN_REPORT_DIALOG').t,
            columns: ['REMOVE', 'REPORT', 'IMAGE', 'AUTO_AD_GROUP_TITLE', 'TERM', 'LINKED_TERM', 'CONVERTERA_AD_GROUP_TITLE', 'MANUAL_AD_GROUP_TITLE', 'TYPE', 'IMPRESSIONS', 'CLICKS', 'INVESTMENT', 'CPC', 'CTR', 'BID_SUGGESTION', 'ACOS', 'ROAS', 'DIRECT_SALES_AMOUNT', 'INDIRECT_SALES_AMOUNT', 'TOTAL_SALES_AMOUNT', 'DIRECT_SALES_VALUE', 'INDIRECT_SALES_VALUE', 'TOTAL_SALES_VALUE'],
        },
    };

    const siteId = 3812506;
    const hotjarVersion = 6;

    if ((process.env.REACT_APP_ENV !== 'development'
        && process.env.REACT_APP_ENV !== 'stage')
        && user !== undefined && user !== null
    ) {
        const { email } = user;

        if (email) {
            Hotjar.init(siteId, hotjarVersion);

            Hotjar.identify(null, {
                email,
            });
        }
    } else {
        console.log('not tracking hotjar | development');
    }

    useDidMount(() => {
        /* eslint-disable no-restricted-syntax */
        for (const key of Object.keys(defaultRoutes)) {
            browserStorageHelper.saveColumnsConfig(
                defaultRoutes[key].page,
                defaultRoutes[key].columns.map((column => {
                    return defaultRoutes[key].translation(column);
                })),
            );
        }
    });

    const TRACKING_ID = 'G-PQZ74XBVY8';
    ReactGA.initialize(TRACKING_ID);

    return (
        <Route
            {...others}
            render={user ? render : RedirectToLogin}
            component={user ? component : memo(RedirectToLogin)}
        />
    );
});

PrivateRoute.propTypes = {
    ...Route.propTypes,
};

const mapStateToProps = ({ user }) => {
    return {
        user,
    };
};

export default connect(mapStateToProps)(PrivateRoute);
