import React, { lazy } from 'react';

import { Redirect } from 'react-router-dom';

import { MaterialIcons } from '~/components/Icon';
import i18n from '~/translations';

import { getRoutesArray } from './manager';

const ChannelViewPage = lazy(() => import('~/domains/channel/ChannelViewPage'));
const IntegrationViewPage = lazy(() => import('~/domains/integrations/IntegrationViewPage'));
const AccountIntegrationAmazonViewPage = lazy(
    () => import('~/domains/accountIntegration/AccountIntegrationAmazonViewPage'),
);
const AccountIntegrationMeliViewPage = lazy(
    () => import('~/domains/accountIntegration/AccountIntegrationMeliViewPage'),
);
const AdListPage = lazy(() => import('~/domains/listing/AdListPage'));
const CampaignViewPage = lazy(() => import('~/domains/campaign/CampaignViewPage'));
const NewCampaignPage = lazy(() => import('~/domains/campaign/NewCampaignPage'));
const LinkerManager = lazy(() => import('~/domains/linkerManager/LinkerManager'));
const AnalysisAd = lazy(() => import('~/domains/ads/AnalysisAdPage'));
const RecommendationViewPage = lazy(() => import('~/domains/viewRecommendation/RecommendationViewPage'));
const BuyboxListingPage = lazy(() => import('~/domains/buybox/BuyboxListingPage'));
const PriceMonitoringPage = lazy(() => import('~/domains/priceMonitoring/PriceMonitoringPage'));
const RecommendationFunilPage = lazy(() => import('~/domains/recommendationFunil/RecommendationFunilPage'));
// const RulesViewPage = lazy(() => import('~/domains/rules/RulesViewPage'));
// const CreateUpdatePricingRulesPage = lazy(() => import('~/domains/rules/CreateUpdatePricingRulesPage'));

const CAMPAIGNS_ROUTES = [
    {
        group: i18n.t('APP_ROUTES:CAMPAIGNS'),
        icon: {
            name: 'list',
            family: MaterialIcons,
        },
        routes: [
            {
                path: '/campanhas',
                menuKey: '/campanhas',
                component: CampaignViewPage,
                text: i18n.t('APP_ROUTES:CAMPAIGNS_LIST'),
                exact: true,
            },
            {
                path: '/campanhas/nova',
                menuKey: '/campanhas',
                component: NewCampaignPage,
                exact: true,
            },
            {
                path: '/campanhas/:campaignId',
                menuKey: '/campanhas',
                component: NewCampaignPage,
                exact: true,
            },
        ],
    },
];

const ANALYSIS_AD_ROUTE = [
    {
        path: '/analise-ads',
        menuKey: '/analise-ads',
        component: AnalysisAd,
        text: i18n.t('APP_ROUTES:ADS_ANALYSIS'),
        exact: true,
        noGroup: true,
        icon: {
            name: 'trending_up',
            family: MaterialIcons,
        },
    },
];

const INTEGRATION_ROUTE = [
    {
        path: '/channel',
        menuKey: '/channel',
        component: ChannelViewPage,
        text: i18n.t('APP_ROUTES:INTEGRATIONS'),
        exact: true,
        noGroup: true,
        icon: {
            name: 'device_hub',
            family: MaterialIcons,
        },
    },
];

const RECOMMENDATION_ROUTE = [
    {
        path: '/recommendation-view/:recommendation',
        menuKey: '/recommendation-view/:recommendation',
        component: RecommendationViewPage,
        exact: true,
    },
];

const RECOMMENDATION_FUNIL_ROUTE = [
    {
        path: '/recommendation-funil',
        menuKey: '/recommendation-funil',
        component: RecommendationFunilPage,
        text: i18n.t('APP_ROUTES:RECOMMENDATIONS'),
        exact: true,
        noGroup: true,
        icon: {
            name: 'info',
            family: MaterialIcons,
        },
    },
];

const ADS_ROUTES = [
    {
        group: i18n.t('APP_ROUTES:ADS'),
        icon: {
            name: 'shopping_cart',
            family: MaterialIcons,
        },
        routes: [
            {
                path: '/anuncios-sem-ads',
                menuKey: '/anuncios-sem-ads',
                component: AdListPage,
                text: i18n.t('APP_ROUTES:ADS_WITH_NO_ADS'),
                exact: true,
            },
            {
                path: '/monitoramento-buybox',
                menuKey: '/monitoramento-buybox',
                component: BuyboxListingPage,
                text: i18n.t('APP_ROUTES:BUYBOX_MONITORING'),
                exact: true,
            },
            {
                path: '/monitoramento-preco',
                menuKey: '/monitoramento-preco',
                component: PriceMonitoringPage,
                text: i18n.t('APP_ROUTES:PRICE_MONITORING'),
                // allowProduction: false,
                exact: true,
            },
            {
                path: '/gerenciador-vinculos',
                menuKey: '/gerenciador-vinculos',
                component: LinkerManager,
                text: i18n.t('APP_ROUTES:LINKER_MANAGER'),
                exact: true,
            },
        ],
    },
];

const CONFIG_ROUTES = [
    {
        path: '/channel/amazon/integrations',
        component: AccountIntegrationAmazonViewPage,
        exact: true,
    },
    {
        path: '/channel/meli/integrations',
        component: AccountIntegrationMeliViewPage,
        exact: true,
    },
    {
        path: '/integration/:marketplace',
        menuKey: '/integration/:marketplace',
        component: IntegrationViewPage,
        exact: true,
    },
];

// const RULES_ROUTES = [
//     {
//         path: '/regras',
//         menuKey: '/regras',
//         component: RulesViewPage,
//         text: i18n.t('APP_ROUTES:RULES_LIST'),
//         exact: true,
//         noGroup: true,
//         allowProduction: false,
//         icon: {
//             name: 'list',
//             family: MaterialIcons,
//         },
//     },
//     {
//         path: '/regras/nova',
//         menuKey: '/regras',
//         component: CreateUpdatePricingRulesPage,
//         exact: true,
//     },
// ];

const HELP_ROUTE = [
    {
        path: 'https://treinamento.anymarket.com.br/course/index.php?categoryid=33/',
        menuKey: '/help',
        component: null,
        text: i18n.t('APP_ROUTES:HELP'),
        exact: true,
        noGroup: true,
        icon: {
            name: 'help',
            family: MaterialIcons,
        },
    },
];

const ROUTES = [
    {
        path: '/',
        component: () => (
            <Redirect to="/app/analise-ads" />
        ),
        exact: true,
    },
    ...ANALYSIS_AD_ROUTE,
    ...RECOMMENDATION_ROUTE,
    ...RECOMMENDATION_FUNIL_ROUTE,
    ...ADS_ROUTES,
    ...CAMPAIGNS_ROUTES,
    ...CONFIG_ROUTES,
    // TODO - Adicionar quando finalizado
    // ...RULES_ROUTES,
    ...INTEGRATION_ROUTE,
    ...HELP_ROUTE,
];

export default ROUTES;

export const allRoutes = getRoutesArray({
    group: 'root',
    routes: ROUTES,
});
